import React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { Button } from '@sumup/circuit-ui';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { FormProps } from '../Form/Form';

import Form from '~/domains/sales-leads/components/Form';
import SectionHeader from '~/shared/components/SectionHeader';
import dataSelector from '~/shared/util/data-selector';
import {
  pageWidthColProps,
  pageFullWidthColProps,
} from '~/shared/styles/helpers';
import { ALIGNMENT } from '~/shared/constants';
import { RichTextPropType } from '~/shared/types/shared';
import { Alignment } from '~/shared/components/ColumnLayout/interfaces';

export const ONE_COLUMN = 'One column';
export const TWO_COLUMN = 'Two columns';
const DATA_SELECTOR = 'form';

function getFieldColProps({
  layout,
  fieldType,
  fullWidth,
  fullWidthForm,
  twoColumnFieldIndex,
}) {
  if (layout === ONE_COLUMN) {
    if (fullWidthForm) {
      return pageFullWidthColProps;
    }
    return pageWidthColProps;
  }

  if (fieldType === 'checkboxField') {
    return { span: '12' };
  }

  return {
    span: fullWidth ? '12' : { default: 12, kilo: 6 },
    shouldClear: !fullWidth && twoColumnFieldIndex % 2 === 1,
  };
}

function getButtonColProps(layout, fullWidthForm) {
  if (layout === ONE_COLUMN) {
    if (fullWidthForm) {
      return pageFullWidthColProps;
    }

    return pageWidthColProps;
  }

  return { span: '12' };
}

const alignmentStyles = ({ alignment }) =>
  alignment === ALIGNMENT.CENTER &&
  css`
    display: flex;
    justify-content: center;
  `;

const AlignmentCol = styled(Col)(alignmentStyles);

const layoutStyles = ({ shouldClear }: { shouldClear?: boolean }) =>
  shouldClear &&
  css`
    & {
      clear: both;
    }
  `;

const StyledCol = styled(Col)(layoutStyles);

export interface FormSectionProps extends FormProps {
  headline: string;
  content: RichTextPropType;
  submitButtonLabel: string;
  submitButtonAlignment: Alignment;
  sectionHeaderAlignment: Alignment;
  layout: typeof ONE_COLUMN | typeof TWO_COLUMN;
  index: number;
  fullWidthButton: boolean;
  additionalContent: any;
  isLoading: boolean;
  fullWidthForm: boolean;
}

/**
 * Displays a form with a headline in a one or two-column layout.
 */
// eslint-disable-next-line react/display-name
const FormSection = React.forwardRef(
  (
    {
      headline,
      content,
      submitButtonLabel = '',
      submitButtonAlignment = ALIGNMENT.LEFT,
      layout = ONE_COLUMN,
      index,
      fullWidthButton,
      additionalContent: AdditionalContent,
      sectionHeaderAlignment = ALIGNMENT.CENTER,
      fullWidthForm = false,
      ...rest
    }: FormSectionProps,
    ref: React.MutableRefObject<any>,
  ) => {
    const [isLoading, setIsLoading] = React.useState(false);

    return (
      <Grid ref={ref} data-selector={dataSelector('section', DATA_SELECTOR)}>
        <SectionHeader
          headline={headline}
          content={content}
          alignment={sectionHeaderAlignment}
          index={index}
        />
        <Row>
          <Form {...rest} setIsLoading={setIsLoading}>
            {(fields, validationErrors) => {
              let twoColumnFieldIndex = 0;
              return (
                <>
                  {!isEmpty(fields) &&
                    fields.map(({ Field, props: fieldProps }) => {
                      if (
                        !fieldProps.fullWidth &&
                        fieldProps.contentType !== 'checkboxField'
                      ) {
                        twoColumnFieldIndex += 1;
                      }
                      return (
                        <StyledCol
                          key={fieldProps.id}
                          {...getFieldColProps({
                            layout,
                            fieldType: fieldProps.contentType,
                            fullWidth: fieldProps.fullWidth,
                            twoColumnFieldIndex,
                            fullWidthForm,
                          })}
                        >
                          <Field
                            {...fieldProps}
                            serverValidationError={
                              validationErrors[fieldProps.salesforceId]
                            }
                          />
                        </StyledCol>
                      );
                    })}

                  {submitButtonLabel && (
                    <AlignmentCol
                      alignment={submitButtonAlignment}
                      {...getButtonColProps(layout, fullWidthForm)}
                    >
                      <Button
                        isLoading={isLoading}
                        type="submit"
                        variant="primary"
                        data-selector={dataSelector(
                          'submit_button',
                          DATA_SELECTOR,
                        )}
                        stretch={fullWidthButton}
                        loadingLabel={submitButtonLabel}
                      >
                        {submitButtonLabel}
                      </Button>
                      {AdditionalContent ? <AdditionalContent /> : null}
                    </AlignmentCol>
                  )}
                </>
              );
            }}
          </Form>
        </Row>
      </Grid>
    );
  },
);

/**
 * @component
 */
export default FormSection;
