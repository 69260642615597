import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Image from '~/shared/components/Image';
import dataSelector from '~/shared/util/data-selector';
import Link from '~/shared/components/Link';

const imageContainerStyles = () => css`
  margin-bottom: 0;
`;

const ImageContainer = styled('figure')(imageContainerStyles);

const renderImageFigure = ({ src, altText }) => (
  <ImageContainer
    data-selector={dataSelector('image-container', 'caption_showcase')}
  >
    <Image
      src={src}
      alt={altText}
      width={800}
      height={800}
      srcSet={[
        { src, size: '400w', height: 400, width: 400 },
        { src, size: '800w', height: 800, width: 800 },
      ]}
      sizes="(min-width: 600px) 40vw, 100vw"
    />
  </ImageContainer>
);

/**
 * Image Media component for CaptionedShowcase
 */
const ShowcaseImage = ({
  imageUrl = '',
  altText = 'Showcase image',
  url = '',
  name,
  id,
}) => {
  if (!url) {
    return null;
  }

  if (imageUrl) {
    return (
      <Link
        href={imageUrl}
        trackingContentEntry={{
          contentType: 'showcaseImage',
          contentEntryName: name,
          contentEntryId: id,
        }}
      >
        <a aria-label={altText}>{renderImageFigure({ src: url, altText })}</a>
      </Link>
    );
  }

  return renderImageFigure({ src: url, altText });
};

ShowcaseImage.propTypes = {
  /**
   * Displayed alt text
   */
  altText: PropTypes.string,
  /**
   * Displayed image source
   */
  url: PropTypes.string.isRequired,
  /**
   * External URL for ShowcaseImage
   */
  imageUrl: PropTypes.string,
  /**
   * Name in contentful for tracking
   */
  name: PropTypes.string,
  /**
   * ID in contentful for tracking
   */
  id: PropTypes.string,
};

/**
 * @component
 */
export default ShowcaseImage;
