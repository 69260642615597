import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { Headline, Body, Hr, List } from '@sumup/circuit-ui';
import { spacing } from '@sumup/circuit-ui/legacy';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { MergeTag } from '@ninetailed/experience.js-next';

import EmbeddedRichtext from '../components/EmbeddedRichtext';
import EmbeddedImage from '../components/EmbeddedImage';
import EmbeddedVideo from '../components/EmbeddedVideo';
import EmbeddedFrame from '../components/EmbeddedFrame';
import EmbeddedHighlight from '../components/EmbeddedHighlight';
import EmbeddedButton from '../components/EmbeddedButton';
import EmbeddedVariable from '../components/EmbeddedVariable';
import EmbeddedLink from '../components/EmbeddedLink';
import { getFields, getContentType } from '../util';
import ColumnAlign from '../components/ColumnAlign';
import { BUTTON_TYPES } from '../components/EmbeddedButton/constants';
import EmbeddedForm from '../components/EmbeddedForm';
import { NodeExtended } from '../types';
import { Alignment, ImagePreset } from '../../ColumnLayout/interfaces';

import Anchor from '~/shared/components/Anchor';
import { TYPES } from '~/shared/constants/sections';
import slugify from '~/shared/util/slugify';
import { NINETAILED_IS_ENABLED } from '~/shared/services/ninetailed/constants';
import ShowcaseEmbeddedVideo from '~/shared/components/CaptionedShowcase/components/ShowcaseEmbeddedVideo';

const headlineStyles = ({
  theme,
  hasSplitLayout,
  hasAlignedButtons,
}: {
  theme?: Theme;
  soloDesign?: Record<string, string>;
  hasSplitLayout?: boolean;
  hasAlignedButtons?: boolean;
}) => css`
  margin-top: ${hasSplitLayout || hasAlignedButtons
    ? '0'
    : 'var(--cui-spacings-giga)'};
  margin-bottom: var(--cui-spacings-mega);
  hyphens: ${theme.hyphens.headingTwo};
`;

const soloTextColorStyles = ({
  soloDesign = {},
}: {
  soloDesign?: Record<string, string>;
  hasSplitLayout?: boolean;
  hasAlignedButtons?: boolean;
}) =>
  soloDesign.textColor &&
  css`
    color: ${soloDesign.textColor};
  `;

const headingSoloStyles = ({
  theme,
  soloDesign = {},
}: {
  theme?: Theme;
  soloDesign?: Record<string, string>;
  hasSplitLayout?: boolean;
  hasAlignedButtons?: boolean;
}) => css`
  ${theme.mq.kilo} {
    ${soloDesign.heading2FontSize &&
    css`
      font-size: ${soloDesign.heading2FontSize};
    `}
    ${soloDesign.heading2LineHeight &&
    css`
      line-height: ${soloDesign.heading2LineHeight};
    `}
  }

  ${theme.mq.untilKilo} {
    ${soloDesign.heading2FontSizeMobile &&
    css`
      font-size: ${soloDesign.heading2FontSizeMobile};
    `}
    ${soloDesign.heading2LineHeightMobile &&
    css`
      line-height: ${soloDesign.heading2LineHeightMobile};
    `}
  }
`;

const listLayoutStyles = ({ size }: { size: string }) => {
  const sizeMap = {
    one: 'var(--cui-spacings-byte)',
    two: 'var(--cui-spacings-kilo)',
  };

  return css`
    padding-left: var(--cui-spacings-byte);
    display: inline-block;
    text-align: left;
    margin-bottom: var(--cui-spacings-mega);

    li:last-child,
    ul:last-child,
    ol:last-child {
      margin-bottom: ${sizeMap[size]};
    }
  `;
};

const StyledList = styled(List)(listLayoutStyles);
const StyledHeadline = styled(Headline)(
  headlineStyles,
  soloTextColorStyles,
  headingSoloStyles,
);
const StyledBody = styled(Body)(soloTextColorStyles);

const StyledBodyBold = styled(Body)(css`
  font-size: inherit !important;
`);

const StyledBodyItalic = styled(Body)(css`
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: italic;
`);

type RenderNodeType = {
  trackingContentEntry?: Record<string, string>;
  soloDesign?: Record<string, string>;
  imagePreset?: ImagePreset;
  numberOfColumns?: number;
  alignButtonId?: string;
  shouldUseH1Heading?: boolean;
};

export const renderNode = ({
  trackingContentEntry,
  soloDesign,
  imagePreset = {},
  numberOfColumns,
  alignButtonId,
  shouldUseH1Heading,
}: RenderNodeType = {}) => ({
  [BLOCKS.HEADING_2]: (node: NodeExtended, children) => (
    <StyledHeadline
      as={shouldUseH1Heading ? 'h1' : node.as}
      size="one"
      id={slugify(children)}
      hasSplitLayout={node.hasSplitLayout}
      hasAlignedButtons={!!alignButtonId}
      soloDesign={soloDesign}
    >
      {children}
    </StyledHeadline>
  ),
  [BLOCKS.HEADING_3]: (node: NodeExtended, children) => (
    <StyledHeadline
      as={node.as}
      size="two"
      id={slugify(children)}
      hasSplitLayout={node.hasSplitLayout}
      hasAlignedButtons={!!alignButtonId}
    >
      {children}
    </StyledHeadline>
  ),
  [BLOCKS.HEADING_4]: (node: NodeExtended, children) => (
    <StyledHeadline
      as={node.as}
      size="four"
      id={slugify(children)}
      hasSplitLayout={node.hasSplitLayout}
      hasAlignedButtons={!!alignButtonId}
    >
      {children}
    </StyledHeadline>
  ),
  [BLOCKS.HEADING_5]: (_, children) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Body size="one" css={spacing({ bottom: 'mega' })}>
      {children}
    </Body>
  ),
  [BLOCKS.PARAGRAPH]: (_, children) => (
    <StyledBody
      size="one"
      soloDesign={soloDesign}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      css={spacing({ bottom: 'mega' })}
    >
      {children}
    </StyledBody>
  ),
  [BLOCKS.QUOTE]: (_, children) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Body variant="quote" size="one" css={spacing({ bottom: 'mega' })}>
      {children}
    </Body>
  ),
  [BLOCKS.HR]: () => <Hr />,
  [INLINES.HYPERLINK]: (node: NodeExtended, children) => (
    <Anchor href={node.data.uri as string}>{children}</Anchor>
  ),
  [BLOCKS.EMBEDDED_ENTRY]: (node: NodeExtended, children) => {
    const contentType = getContentType(node) as string;
    const fields = getFields(node);
    const { align } = fields as { align: Alignment };
    const { fullWidthButtons } = node;

    const { imagePresetSize, imagePresetAlignment } = imagePreset;
    const mediaAlignment = imagePresetAlignment || align;

    switch (contentType) {
      case 'genericImage':
        return (
          <ColumnAlign
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            align={mediaAlignment}
            imagePresetSize={imagePresetSize}
          >
            <EmbeddedImage
              {...fields}
              contentType={TYPES.COLUMN_LAYOUT}
              shouldUseDefaultSizes={numberOfColumns === 1}
            >
              {children}
            </EmbeddedImage>
          </ColumnAlign>
        );
      case 'showcaseEmbeddedVideo':
        return (
          <ColumnAlign
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            align={mediaAlignment}
            imagePresetSize={imagePresetSize}
          >
            <ShowcaseEmbeddedVideo {...fields} isOnRichtext>
              {children}
            </ShowcaseEmbeddedVideo>
          </ColumnAlign>
        );
      case 'embeddedVideo':
        return <EmbeddedVideo {...fields}>{children}</EmbeddedVideo>;
      case 'embeddedRichtext':
        return <EmbeddedRichtext {...fields}>{children}</EmbeddedRichtext>;

      case 'embeddedFrame':
        return <EmbeddedFrame {...fields}>{children}</EmbeddedFrame>;

      case 'embeddedHighlight':
        return <EmbeddedHighlight {...fields}>{children}</EmbeddedHighlight>;

      case 'embeddedButton': {
        const isAlignedToAllButtons =
          alignButtonId && alignButtonId === fields.id;

        return fields.buttonType === BUTTON_TYPES.CHAT ? (
          <div>
            <EmbeddedButton
              fullWidthButtons={fullWidthButtons}
              trackingContentEntry={trackingContentEntry}
              isInline
              {...fields}
            >
              {children}
            </EmbeddedButton>
          </div>
        ) : (
          <EmbeddedButton
            fullWidthButtons={fullWidthButtons}
            trackingContentEntry={trackingContentEntry}
            isAlignedToAllButtons={isAlignedToAllButtons}
            {...fields}
          >
            {children}
          </EmbeddedButton>
        );
      }
      case 'embeddedForm':
        return (
          <EmbeddedForm
            {...fields}
            noTopMargin
            fullWidthForm={numberOfColumns > 1}
          >
            {children}
          </EmbeddedForm>
        );

      default:
        return null;
    }
  },
  [INLINES.EMBEDDED_ENTRY]: (node: NodeExtended, children) => {
    const contentType = getContentType(node) as string;
    const fields = getFields(node);

    if (
      node?.data?.target?.contentType === 'nt_mergetag' &&
      node?.data?.target?.nt_mergetag_id &&
      NINETAILED_IS_ENABLED
    ) {
      return <MergeTag id={node.data.target.nt_mergetag_id} />;
    }

    switch (contentType) {
      case 'embeddedVariable':
        return <EmbeddedVariable {...fields} />;

      case 'embeddedButton':
        return (
          <EmbeddedButton {...fields} isInline>
            {children}
          </EmbeddedButton>
        );

      case 'embeddedLink':
        return <EmbeddedLink {...fields} />;
      case 'embeddedStrikethroughText':
        return <s>{fields.content}</s>;
      default:
        return null;
    }
  },
  [BLOCKS.UL_LIST]: (node: NodeExtended, children) => (
    <div>
      <StyledList {...node.alignments} size="one">
        {children}
      </StyledList>
    </div>
  ),
  [BLOCKS.OL_LIST]: (node: NodeExtended, children) => (
    <div>
      <StyledList size="one" variant="ordered" {...node.alignments}>
        {children}
      </StyledList>
    </div>
  ),
});

export const renderMark = {
  [MARKS.BOLD]: (text) => (
    <StyledBodyBold
      as="strong"
      size="one"
      variant="highlight"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      css={spacing({ bottom: 'mega' })}
    >
      {text}
    </StyledBodyBold>
  ),
  [MARKS.ITALIC]: (text) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <StyledBodyItalic as="em" size="one" css={spacing({ bottom: 'mega' })}>
      {text}
    </StyledBodyItalic>
  ),
};
