import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { elb } from '@elbwalker/walker.js';

import ShowcaseImage from '../ShowcaseImage';

import useIsLiteMode from '~/shared/hooks/use-is-lite-mode';
import useViewportName from '~/shared/hooks/use-viewport-name';
import { VIEWPORTS } from '~/shared/constants';
import * as FullMediaBackgroundVideoService from '~/shared/components/FullMedia/components/FullMediaBackgroundVideo/FullMediaBackgroundVideoService';
import * as Analytics from '~/shared/services/analytics';

const videoStyles = () => css`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Video = styled('video')(videoStyles);

type Props = {
  id?: string;
  name?: string;
  contentType?: string;
  /**
   * The mp4 video source URL.
   */
  mp4?: string;
  /**
   * * The webM video source URL.
   */
  webm?: string;
  /**
   * A fallback image that's shown while the video is loading.
   * Ideally it should match the first frame of the video.
   */
  placeholder?: string;
  /**
   * An alternative description of the video. Crucial for accessibility.
   */
  alt?: string;
  autoPlay?: boolean;
  loop?: boolean;
  showControls?: boolean;
  isOnRichtext?: boolean;
};
const ShowcaseEmbeddedVideo = ({
  id,
  name,
  contentType,
  mp4,
  webm,
  placeholder,
  alt = 'showcase embedded video',
  autoPlay,
  loop,
  showControls,
  isOnRichtext,
}: Props) => {
  const hasPlayed = useRef(false);
  const viewport = useViewportName();
  const isLiteMode = useIsLiteMode();
  const [renderVideo, setRenderVideo] = useState(false);

  const isMobile = viewport === VIEWPORTS.MOBILE;

  useEffect(() => {
    setRenderVideo(true);
  }, []);

  if (!mp4 && !webm && !placeholder) {
    return null;
  }

  const placeholderSrc = FullMediaBackgroundVideoService.getPlaceholderUrl(
    placeholder,
    viewport,
  );
  if ((!renderVideo && placeholderSrc) || isLiteMode) {
    return <ShowcaseImage altText={alt} url={placeholderSrc} />;
  }

  const trackEvent = (action) => {
    Analytics.sendEvent({
      action,
      event: 'interaction',
      target: 'Mkt_Web',
      contentEntryId: id,
      contentEntryName: name,
      contentType,
      destinationCategory: Analytics.destinationCategory.UI_OPERATION,
      destinationUrl: undefined,
    });
  };

  const onPlay = () => {
    trackEvent('play@showcase_embedded_video');

    if (!hasPlayed.current) {
      elb('video started', { video_id: id });
    } else {
      elb('video relaunched', { video_id: id });
    }
    hasPlayed.current = true;
  };

  const onEnded = () => {
    elb('video completed', { video_id: id });
  };

  return (
    <Video
      autoPlay={!isOnRichtext && isMobile ? false : autoPlay}
      controls={!isOnRichtext && isMobile ? true : showControls}
      loop={!isOnRichtext && isMobile ? false : loop}
      poster={placeholderSrc}
      data-selector="showcase-embedded-video"
      muted
      playsInline
      preload="metadata"
      onPlay={onPlay}
      onPause={() => trackEvent('pause@showcase_embedded_video')}
      onEnded={onEnded}
    >
      {webm && <source src={webm} type="video/webm" />}
      {mp4 && <source src={mp4} type="video/mp4" />}
    </Video>
  );
};

/**
 * @component
 */
export default React.memo(
  ShowcaseEmbeddedVideo,
  (prevProps, nextProps) =>
    prevProps.mp4 === nextProps.mp4 && prevProps.webm === nextProps.webm,
);
